import {SPECS as SdkSpecs} from '@wix/wixstores-client-storefront-sdk/dist/es/src/constants';

export const SPECS = {
  ...SdkSpecs,
  SUBSCRIPTION_PLAN_USE_TPA_RADIO_BUTTON: 'specs.stores.SubscriptionPlanUseTpaRadioButton',
  USE_LIGHTBOXES: 'specs.stores.UseLightboxes',
  USE_DROPDOWN_TPA_LABEL: 'specs.stores.UseDropdownTpaLabel',
  BUY_NOW_WITHOUT_GC: 'specs.stores.BuyNowWithoutGC',
  CART_TOOLTIP_WITHOUT_NUMBER: 'specs.stores.CartTooltipWithoutNumber',
  EDITOR_OOI: 'specs.stores.EditorOOI',
  BI_REPORT_113_EVENT_TO_130_SOURCE: 'specs.stores.BiReport113EventTo130Source',
  WOW_IMAGE_IN_PRODUCT_PAGE: 'specs.stores.WowImageInProductPage',
  WOW_IMAGE_IN_PRODUCT_PAGE_MOBILE: 'specs.stores.WowImageInProductPageMobile',
  IS_NEGATIVE_INVENTORY_ENABLED: 'specs.stores.isNegativeInventoryEnabled',
  ACTIVE_DISABLED_VARIANTS: 'specs.stores.ActiveDisabledVariants',
  MULTI_CURRENCY_POC: 'specs.stores.MultiCurrencyPOC',
  DISABLE_MOBILE_NATIVE_DROPDOWN: 'specs.stores.DisableMobileNativeDropdown',
} as const;
